<template>


  <div>


    <b-form-row>
      <b-col md="4" cols="6">
        <b-form-group>
          <label for="age">Age</label>
          <!--        <b-form-input id="age" ref='age' type="number" v-model="age" :state="age && validAge" @input="calc"/>
                  <small v-if="age && !validAge">Enter age between 10 and 100</small>-->
          <b-form-select id="age" v-model="age" :options="ageOptions" @change="calc">
            <template #first>
              <b-form-select-option :value="null" disabled>Select...</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="4" cols="6">
        <b-form-group>
          <label for="time">Gender</label>
          <b-form-select v-model="gender" @change="resetAndCalc">
            <template #first>
              <b-form-select-option :value="null" disabled>Select...</b-form-select-option>
            </template>
            <b-form-select-option value="male">Male</b-form-select-option>
            <b-form-select-option value="female">Female</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="4" cols="6">
        <b-form-group>
          <label for="distance">Distance</label>
          <b-form-select v-model="distance" :options="displayDistances" @change="resetAndCalc">

            <template #first>
              <b-form-select-option :value="null" disabled>Select...</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="4" cols="6">
        <b-form-group>
          <label for="time">Time</label>
          <b-form-input id="time" ref="time" placeholder="h:mm:ss" v-model="time" :state="time && validTime" @input="calc"/>
          <small v-if="time && !validTime">E.g. '0:19:27' or '2:09:05'</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">

        <div v-if="ageGradingPercentage" class="result mt-2 mb-3">
          <div><b>{{ ageGradingPercentage }}%</b> - {{ standard }}</div>
          <div v-if="openCategoryEquivalentTime">Open category equivalent time - {{openCategoryEquivalentTime}}&nbsp;&nbsp;<b-icon v-b-modal.ocinfo icon="info-circle" variant="primary" title="Info" style="cursor:pointer"></b-icon></div>
          <b-modal id="ocinfo" ok-only title="Open category equivalent time">
            <p>This is the equivalent time for the open standard, typically what a 20-29 year old would have to run to achieve the same percentage.</p>
            <!-- Add any other content or components you need inside the modal -->
          </b-modal>
        </div>
        <div class="mt-2" v-if="distance && gender && age" style="margin-top:1rem;font-size:0.8rem">
          <template v-if="isGazelles">
            <label>Required times for standards:</label>
            <table class="standards">

              <tr v-for="(percentage,label) in filteredStandards" :key="label">
                <td>{{ label }} ({{ percentage }}%)</td>
                <td>{{ requiredTime(percentage) }}</td>
              </tr>

            </table>
          </template>
          <template v-else>
            <label>Required times for age grading:</label>
            <table class="standards">

              <tr v-for="percentage in percentages" :key="percentage">
                <td>{{ percentage }}%</td>
                <td>{{ requiredTime(percentage) }}</td>
              </tr>

            </table>
          </template>


        </div>
      </b-col>
    </b-form-row>
  </div>

</template>
<script>
import Papa from 'papaparse';

export default {
   data: () => ({
    time: '',
    openCategoryEquivalentTime: '',
    raceDistances: ['5 km', '5 Mile', '10 km', '10 Mile', 'H. Mar', 'Marathon'],
    standards: {
      'DIAMOND': 85,
      'EMERALD': 80,
      'PLATINUM': 72.5,
      'GOLD': 65,
      'SILVER': 60,
      'BRONZE': 55,
      'COPPER': 47.5,
      'GAZELLES': 5
    },
    distance: null,
    ageTable: {},
    ageGradingPercentage: null,
    age: null,
    ageOptions: Array.from({length: 91}, (_, index) => 10 + index),
    gender: null

  }),


  computed: {

     percentages(){
       const descendingArray = [];
       for (let i = 100; i >= 50; i -= 5) {
         descendingArray.push(i);
       }
       return descendingArray
     },

    displayDistances(){
      return [
        { value: '5 km', text: '5K' },
        { value: '5 Mile', text: '5 miles' },
        { value: '10 km', text: '10K' },
        { value: '10 Mile', text: '10 miles' },
        { value: 'H. Mar', text: 'Half Marathon' },
        { value: 'Marathon', text: 'Marathon' },
      ]
    },


    validTime() {
      const timeRegex = /^\d{1,2}:[0-5][0-9]:[0-5][0-9]$/;
      return timeRegex.test(this.time);
    },
    validAge() {
      // Check if inputNumber is a number between 10 and 100
      const number = parseInt(this.age);
      return !isNaN(number) && number >= 10 && number <= 100;
    },
    standard() {
      for (const [label, percentage] of Object.entries(this.standards)) {
        if (this.ageGradingPercentage >= percentage) {
          return label;
        }
      }
      return '';
    },
    filteredStandards() {
      const {GAZELLES, ...rest} = this.standards;
      let f = {...rest};
      return f;
    },
    isGazelles(){
      return (window.location.href.includes("norfolkgazelles.co.uk"))
    }

  },
  mounted() {
    // Call the loadAndParseCSV method when the app is mounted to load the CSV data
    this.loadAndParseCSV();
    this.loadUserData();
  },



  methods: {


    inputsValid: function () {
      return (this.validAge && this.validTime && this.distance && this.gender);
    },

    loadAndParseCSV() {
      ['male', 'female'].forEach(gender => {
        Papa.parse('./data/wava-' + gender + '.csv', {
          download: true,
          headers: false,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (results) => {
            if (results.data && results.data.length > 0) {
              let row = results.data[1];
              let headers = {};
              this.raceDistances.forEach(item => {
                const index = row.indexOf(item);
                if (index !== -1) {
                  headers[item] = index;
                }
              });
              console.log(headers);
              const indices = Object.values(headers);
              //console.log(indices);
              let arr = {};
              for (let i = 3; i < results.data.length; i++) {
                const row = results.data[i];
                const timesArr = indices.map(index => row[index]);
                let times = {};
                const age = row[0];
                this.raceDistances.forEach((item, index) => {
                  times[this.raceDistances[index]] = timesArr[index];
                });
                arr[age] = times;

              }
              //console.log(arr);

              this.ageTable[gender] = arr;
              //console.log(this.ageTable);
            } else {
              console.error('Failed to parse CSV data.');
            }
          },
        });
      });

    },
    getBestTime(age, distance, gender) {
      //console.log(this.ageTable);
      let table = this.ageTable[gender];
      let times = table[age];
      return times[distance];
    },
    getBestOpenCategoryTime(distance, gender) {
      //console.log(this.ageTable);
      let table = this.ageTable[gender];
      let times = table['OC'];
      return times[distance];
    },
    convertTimeToSeconds(time) {
      const timeParts = time.split(':');
      if (timeParts.length === 3) {
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const seconds = parseInt(timeParts[2]);
        return hours * 3600 + minutes * 60 + seconds;
      }
      return null;
    },
    requiredTime(percentage) {
      const age = parseInt(this.age);
      const bestTime = this.getBestTime(age, this.distance, this.gender);
      const bestOcTime = this.getBestOpenCategoryTime(this.distance, this.gender);

      if (bestTime) {
        const bestTimeInSeconds = this.convertTimeToSeconds(bestTime);
        //console.log("bestTimeInSeconds = " + bestTimeInSeconds);
        const seconds = bestTimeInSeconds / (percentage / 100);
        return this.convertSecondsToTime(seconds);

      } else {
        return ""
      }
    },
    convertSecondsToTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.round(seconds % 60);

      const formattedTime = `${this.padWithZero(hours)}:${this.padWithZero(minutes)}:${this.padWithZero(remainingSeconds)}`;
      return formattedTime;
    },
    padWithZero(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    resetAndCalc() {
      if (this.time) this.time = '';
      this.calc();
    },

    calc() {
      this.storeUserData();
      if (this.inputsValid()) {
        const age = parseInt(this.age);
        const time = this.time;
        const bestTime = this.getBestTime(age, this.distance, this.gender);
        //console.log("bestTime = "+bestTime)
        if (bestTime) {
          const enteredTimeInSeconds = this.convertTimeToSeconds(time);
          const bestTimeInSeconds = this.convertTimeToSeconds(bestTime);
          this.ageGradingPercentage = ((bestTimeInSeconds / enteredTimeInSeconds) * 100).toFixed(2);
          const bestOcTime = this.getBestOpenCategoryTime(this.distance, this.gender);
          if (bestOcTime) {
            const bestOcTimeInSeconds = this.convertTimeToSeconds(bestOcTime);
            const seconds = bestOcTimeInSeconds / (this.ageGradingPercentage / 100);
            this.openCategoryEquivalentTime=this.convertSecondsToTime(seconds);
          }
        } else {
          this.ageGradingPercentage = null;
        }
      } else {
        this.ageGradingPercentage = null;
      }
    },
    storeUserData() {
      const userData = {
        gender: this.gender,
        age: this.age
      }
      localStorage.setItem('userData', JSON.stringify(userData));
    },
    loadUserData() {
      const savedUserData = localStorage.getItem('userData');
      if (savedUserData) {
        const userData = JSON.parse(savedUserData);
        this.age = userData.age;
        this.gender = userData.gender;
      }
    }


  }

}

</script>
<style type="text/scss" scoped>
table.standards {
  border: 1px dotted black;


td {
  padding: 3px;
  border: 1px dotted black;
  font-size: 0.8rem;
}

}


</style>