<template>
  <div>
    <b-row>
      <b-col md="6" cols="6">
        <label for="restingHeartRate">Resting Heart Rate <b-icon id="popover-restingHeartRate" icon="question-circle" aria-hidden="true"></b-icon></label>
        <b-form-input id="restingHeartRate" v-model="restingHeartRate" placeholder="E.g. 50" type="number" min="25" max="100" :state="validRestingHeartRate"/>

      </b-col>

      <b-col md="6" cols="6">
        <label for="maxHeartRate">Max Heart Rate&nbsp;<b-icon id="popover-maxHeartRate" icon="question-circle" aria-hidden="true"></b-icon></label>
        <b-form-input id="maxHeartRate" v-model="maxHeartRate" placeholder="E.g. 175" type="number" min="125" max="250" :state="validMaxHeartRate"/>

      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" cols="12">

        <div v-show="heartRates">
          <table id="heart-rates-table" class="mt-3">
            <thead>
            <tr>
              <th class="percentage">%</th>
<!--              <th class="hrmax">HRmax&nbsp;<a href="" @click.prevent="showHrmaxHelp=!showHrmaxHelp"><b-icon icon="question-circle" aria-hidden="true"></b-icon></a></th>-->
              <th class="hrmax">HRmax&nbsp;<b-icon id="popover-hrmax" icon="question-circle" aria-hidden="true"></b-icon></th>
              <th class="hrr">HRR&nbsp;<b-icon id="popover-hrr" icon="question-circle" aria-hidden="true"></b-icon></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="hr in heartRates" :key="hr.percentage">
              <td >{{hr.percentage}}%</td><td>{{hr.hrMax}}</td><td>{{hr.hrr}}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </b-col>
    </b-row>
    <b-popover target="popover-restingHeartRate" triggers="hover">
      <template #title>Resting Heart Rate</template>
      Resting heart rate is the number of heartbeats per minute (bpm) while your are at rest, awake, and not experiencing any particular physical or emotional stress. It's usually measured in the morning, right after waking up and before getting out of bed, or during periods of deep relaxation.
    </b-popover>
    <b-popover target="popover-maxHeartRate" triggers="hover">
      <template #title>Max Heart Rate</template>
      To make best use of heart rate training, you should enter a properly measured figure for Max Heart Rate (from a test), rather than one from a standard formula like 220 minus age - the 'rule-of-thumb' figures can be a long way out for many people.
    </b-popover>
    <b-popover target="popover-hrmax" triggers="hover">
      <template #title>HRmax</template>
      The figures in the 'HRmax' column are simple percentages of your 'Max Heart Rate' figure.
    </b-popover>

    <b-popover target="popover-hrr" triggers="hover">
      <template #title>Heart Rate Reserve</template>
      The figures in this column use your heart rate reserve, which is often regarded as a more useful metric in trained runners, as it takes into account your resting heart rate (which tends to be lower the better trained you are). Your heart rate reserve is the difference between your resting heart rate and your maximum heart rate. The values shown are percentages of your heart rate reserve, plus your resting heart rate - what is known as the Karvonen formula.
    </b-popover>

  </div>
</template>

<script>
export default {
  name: "HeartRate",
  data: () => ({
    restingHeartRate:null,
    maxHeartRate: null
  }),
  mounted() {
    let item=localStorage.getItem('hrdata');
    if(item) {
      let obj=JSON.parse(item);
      this.restingHeartRate=obj.restingHeartRate;
      this.maxHeartRate=obj.maxHeartRate;
    }


  },
  computed: {
    validMaxHeartRate(){
      if(!this.maxHeartRate) return false;
      return this.maxHeartRate>=125 && this.maxHeartRate<=250;
    },
    validRestingHeartRate(){
      if(!this.restingHeartRate) return false;
      return this.restingHeartRate>=25 && this.restingHeartRate<=100;
    },
    hrr(){
      if(this.validMaxHeartRate && this.validRestingHeartRate) {
        localStorage.setItem("hrdata",JSON.stringify({"restingHeartRate":this.restingHeartRate,"maxHeartRate":this.maxHeartRate}));

        return this.maxHeartRate - this.restingHeartRate;
      } else {
        return 0;
      }
    },

    heartRates() {
      if(this.hrr) {
        let arr=[];
        for(let i=50;i<=100;i+=5){
          let hrMax=parseInt(((i/100)*this.maxHeartRate).toFixed(0));
          let hrr=parseInt(((i/100)*this.hrr).toFixed(0))+parseInt(this.restingHeartRate);
          //console.log("hrMax for "+i+" = "+hrMax)
          arr.push({"percentage":i,"hrMax":hrMax,"hrr":hrr});

        }
        return arr;
      } else {
        return null;
      }
    },

  },
  methods: {

  }
}
</script>



<style type="text/scss" scoped>
  #heart-rates-table{
    width:100%;
    border: 1px dotted black;
  /*  border-collapse: collapse;
    border: 1px solid silver;*/
  }

  td, th{
    text-align: right;
    padding: 3px;
    border: 1px dotted black;
    font-size: 0.8rem;


  }
  th.percentage{
     width:20%;
   }
  th.hrmax,th.hrr{
    width:40%;
  }
</style>