<template>



  <div>

    <!--      <p class="instruction">Enter distance and target time to get required pace:</p>-->

    <b-form-row>
      <b-col md="5" cols="6">
<!--
        <b-form-group>
          <label for="distance">Distance ({{conversion=='miles-to-km'?'miles':'km'}})</label>
          <b-form-input v-model="distance" :state="validDistance"/>


        </b-form-group>
-->

        <label for="distance">Distance</label>
        <b-input-group class="mb-3">

          <b-form-input id="distance" placeholder="0" v-model="distance" :state="validDistance" :formatter="checkNumericInput" type="text"/>

          <select v-model="units" class="form-control" style="max-width:6em" @change="calc">
            <option v-for="opt in unitOptions" :key="opt.value">{{ opt.value }}</option>

          </select>
        </b-input-group>
      </b-col>

    </b-form-row>

    <b-form-row>
      <b-col cols="12">
        <div v-if="result" class="result mt-1">{{result}}
        </div>
      </b-col>
    </b-form-row>
  </div>

</template>
<script>

export default {
  data: () => ({
    units:'miles',
    distance:'',
    result:null

  }),
  watch: {
    'distance': function () {
      this.calc();
    },

  },

  computed: {

    validDistance() {
      if (!this.distance) return null;
      return this.isValidDistance(this.distance);
    },

  },
  mounted() {
    //HtmlDurationPicker.init();
  },
  methods: {

    reset:function(){
      this.distance='';
    },

    calc: function () {
      //console.log("Calculate everything");

      if (this.validDistance) {
        if(this.units=='miles') {
          let val=this.round(this.distance*1.60934,3);
          this.result=val+" km";
        } else {
          let val=this.round(this.distance/1.60934,3);
          this.result=val+" miles";
        }


      } else {
        this.result="";
      }
    }
  }

}

</script>
<style type="text/scss" scoped>


</style>