import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
//import './assets/html-duration-picker.min'
import './assets/custom.scss'
import App from './App.vue'
import VueMask from 'v-mask'
Vue.use(VueMask);

import '@/mixins/CalcMixin'
import './registerServiceWorker'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
