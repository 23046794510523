<template>



  <div>
    <b-form-group>
      <b-form-radio-group v-model="units" size="sm"
                          :options="[{ text: 'Miles', value: 'miles' },{ text: 'Km', value: 'km' }]" @change="calc">
      </b-form-radio-group>
    </b-form-group>
    <b-form-group>
      <b-form-radio-group id="conversion" v-model="conversion" size="sm"
                          :options="[{ text: 'Pace to speed', value: 'pace-to-speed' },{ text: 'Speed to pace', value: 'speed-to-pace' }]" @change="reset">
      </b-form-radio-group>
    </b-form-group>
    <!--      <p class="instruction">Enter distance and target time to get required pace:</p>-->

    <b-form-row>
      <b-col md="4" cols="6">
        <b-form-group v-if="conversion=='pace-to-speed'">
          <label for="pace">Pace  ({{(units=='miles'?' mins/mile':' mins/km')}})</label>
          <b-form-input id="pace" placeholder="mm:ss" v-model="pace" :state="validPace" :formatter="checkTimeInput"/>
          <small v-if="validPace==false">Enter pace as e.g. '9:05'</small>

        </b-form-group>
        <b-form-group v-else>
          <label for="speed">Speed ({{ units=='miles'?'mph':'km/h' }})</label>
          <b-form-input id="speed" placeholder="0" v-model="speed" type="text"
                        :state="validSpeed" :formatter="checkNumericInput"/>


        </b-form-group>
      </b-col>

    </b-form-row>

    <b-form-row>
      <b-col cols="12">
        <div v-if="result" class="result mt-1">{{result}}
        </div>
      </b-col>
    </b-form-row>

<!--    <b-popover target="conversion" triggers="hover" placement="topleft" content="Pace is e.g. minutes per kilometre or mile, while speed is kilometres or miles per hour"/>-->
  </div>

</template>
<script>

export default {
   data: () => ({
     units:"miles",
    conversion: 'pace-to-speed',
    pace:'',
    speed: '',
    result:''

  }),
  watch: {
    'pace': function () {
      this.calc();
    },
    'speed': function () {
      this.calc();
    }
  },

  activated() {
    if(sessionStorage.getItem('pace')){
      this.pace=sessionStorage.getItem('pace');
    }
    if(sessionStorage.getItem('units')){
      this.units=sessionStorage.getItem('units');
    }
    //console.log("PaceCalculator activated");
  },

  computed: {

    validSpeed() {
      if (!this.speed) return null;
      return /^\d{0,2}(?:[.]\d{1,3})?$/.test(this.speed);
    },
    validPace() {
      if (!this.pace) return null;
      return this.isValidPace(this.pace);
    },
  },
  mounted() {
    //HtmlDurationPicker.init();
  },
  methods: {
    inputsValid: function () {
      if(this.conversion=='pace-to-speed'){
        return this.pace && this.validPace;
      } else {
        return this.speed && this.validSpeed;
      }

    },
    reset:function(){
      this.pace='';
      this.speed='';

},

    calc: function () {
      //console.log("Calculate everything");

      if (this.inputsValid()) {
        if(this.conversion=='pace-to-speed') {
          this.result=this.paceToMph(this.pace)+(this.units == 'miles' ? ' mph' : ' km/h');
        } else {
          this.result=this.mphToPace(this.speed)+(this.units == 'miles' ? ' mins/mile' : ' mins/km');
        }


      } else {
        this.result="";
      }
    }
  }

}

</script>
<style type="text/scss" scoped>


</style>