<template>


  <div>
    <b-form-group>
      <b-form-radio-group v-model="units" size="sm"
                          :options="unitOptions" @change="calc">
      </b-form-radio-group>
    </b-form-group>
    <!--      <p class="instruction">Enter distance and target time to get required pace:</p>-->
    <b-form-row>
      <b-col md="4" cols="6">
        <b-form-group>
          <label for="pace">Pace ({{ (units == 'miles' ? ' mins/mile' : ' mins/km') }})</label>
          <b-form-input id="pace" placeholder="mm:ss" v-model="pace" :state="validPace" :formatter="checkTimeInput"/>
          <small v-if="validPace==false">Enter pace as e.g. '9:05'</small>

        </b-form-group>
      </b-col>

      <b-col md="4" cols="6">
        <b-form-group>
          <label for="duration">Run Time</label>
          <b-form-input id="duration" placeholder="h:mm:ss" v-model="duration" :state="validDuration"
                        :formatter="checkTimeInput"/>
          <small v-if="validDuration==false">E.g. '19:27' or 2:09:05'</small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="12">
        <div v-if="distance" class="result mt-1">Distance run is: <b>{{ distance }} {{ units }}</b>
        </div>
      </b-col>
    </b-form-row>
  </div>

</template>
<script>
//import { vMaska } from "maska"
export default {
  data: () => ({
    units: "miles",
    duration: '',
    pace: '',
    distance: ''

  }),
  //directives: { maska: vMaska },
  watch: {
    'pace': function () {
      this.calc();
    },
    'duration': function () {
      this.calc();
    }
  },
  activated() {
    if(sessionStorage.getItem('pace')){
      this.pace=sessionStorage.getItem('pace');
    }
    if(sessionStorage.getItem('units')){
      this.units=sessionStorage.getItem('units');
    }
  },

  computed: {

    validPace() {
      if (!this.pace) return null;
      return this.isValidDuration(this.pace);
    },
    validDuration() {
      if (!this.duration) return null;
      return this.isValidDuration(this.duration);
    }
  },
  mounted() {
    //HtmlDurationPicker.init();
  },
  methods: {
    inputsValid() {
      return this.duration && this.validDuration && this.pace && this.validPace
    },

    calc() {

      if (this.inputsValid()) {

        let pace = this.pace;

        let parts = pace.split(":");
        let paceMins = parseInt(parts[0], 10);
        let paceSecs = parts.length > 1 ? parseFloat(parts[1], 10) : 0.0;
        let secsPerMile = (paceMins * 60) + paceSecs;
        let runTime = this.duration;
        parts = runTime.split(":");
        let runHours = 0, runMins = 0, runSecs = 0;
        runHours = parseInt(parts[0], 10);
        if (parts.length > 1) {
          runMins = parseInt(parts[1], 10);
          if (parts.length > 2) {
            runSecs = parseInt(parts[2], 10);
          }
        }
        let totalRunSecs = (3600 * runHours) + (60 * runMins) + runSecs;
        this.distance = this.round(totalRunSecs / secsPerMile, 3);
        sessionStorage.setItem('distance',this.distance);
      }
    }
  }

}

</script>
<style type="text/scss" scoped>


</style>