import Vue from 'vue'
import { BIcon, BIconArrowUp, BIconArrowDown,BIconQuestionCircle } from 'bootstrap-vue'


Vue.mixin({
    components: {
        BIcon, BIconQuestionCircle
    },

    data: () => ({
/*        distances:{
            '5K':5,
            '5 miles':8.0467,
            '10K':10,
            '10 miles':16.0934,
            'Half Marathon':21.0975,
            'Marathon':42.195
        },*/

    }),
    created: function () {
        this.KM_PER_MILE = 1.60934;
        this.distances=[{name:'5K', distance:5},{name:'5 miles', distance:8.0467},
            {name:'10K',distance:10},{name:'10 miles',distance:16.0934},
            {name:'Half Marathon',distance:21.0975},{name:'20 miles',distance:32.1868},{name:'Marathon',distance:42.195}
            ];
        this.trackDistances=[{name:'400m', distance:0.4},{name:'800m', distance:0.8},
            {name:'1K',distance:1},{name:'1 mile',distance:1.60934}];

        //this.unitOptions=[{ text: 'Miles', value: 'miles' },{ text: 'Km', value: 'km' },{test:'Metres',value:'metres'}];
        this.unitOptions=[{ text: 'Miles', value: 'miles' },{ text: 'Km', value: 'km' }];

    },
    methods: {
        checkTimeInput: function (val) {
            return val.replace(/[^0-9.:]/g, '');
        },
        checkNumericInput: function (val) {
            return val.replace(/[^0-9.]/g, '');
        },

        round: function (amount, places) {
            var n = Math.pow(10, places);
            return Math.round(amount * n) / n;
        },
        isValidDistance(distance) {
            return /^\d{0,4}(?:[.]\d+)?$/.test(distance);
        },
        isValidPace(pace) {
            let regex = /^[0-5]?[0-9]:[0-5][0-9](\.\d{1,2})?$/;
            return regex.test(pace);
        },
        isValidDuration(duration) {
            let regex1 = /^\d{1,2}:[0-5][0-9]:[0-5][0-9](\.\d{1,2})?$/;
            let regex2 = /^[1-5]?[0-9]:[0-5][0-9](\.\d{1,2})?$/;
            return regex1.test(duration) || regex2.test(duration);
        },
        validateQty: function (event) {
            var key = window.event ? event.keyCode : event.which;
            if (event.keyCode == 8 || event.keyCode == 46
                || event.keyCode == 37 || event.keyCode == 39) {
                return true;
            } else if (key < 48 || key > 57) {
                return false;
            } else return true;
        },
        paceToMph: function (pace) {
            var parts = pace.split(":");
            var mins = 0;
            var secs = 0.0;
            mins = parseInt(parts[0], 10)
            if (parts.length > 1) {
                secs = parseFloat(parts[1], 10)
            }
            var secsPerMile = (mins * 60) + secs;
            return this.round(3600 / secsPerMile, 2);

        },

        mphToPace: function (mph) {
            if (mph > 0) {
                var secsPerMile = this.round(3600 / mph, 1);
                var mins = Math.floor(secsPerMile / 60);
                var secs = this.round(secsPerMile % 60, 1);
                return mins + ":" + this.pad(secs);
            }
        },
        pad: function (int) {
            return int = (int < 10) ? '0' + int : int;
        }

    }
})