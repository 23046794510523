<template>


      <div>

<!--      <b-form-group>
        <b-form-radio-group v-model="units" size="sm"
                            :options="this.unitOptions">
        </b-form-radio-group>
      </b-form-group>-->
<!--      <p class="instruction">Enter distance and target time to get required pace:</p>-->
      <b-form-row>
        <b-col md="5" cols="9">


            <label for="distance">Distance</label>
            <b-input-group class="mb-3">
              <template #prepend>
                <b-dropdown size="sm" variant="outline-secondary">
                  <b-dropdown-item v-for="d in distances" :key="d.name" @click="setDistance(d.distance)">{{ d.name}}</b-dropdown-item>
                </b-dropdown>
              </template>
              <b-form-input id="distance" placeholder="0" v-model="distance" :state="validDistance" :formatter="checkNumericInput" type="text"/>

              <select v-model="units" class="form-control" style="max-width:6em">
                <option v-for="opt in unitOptions" :key="opt.value">{{ opt.value }}</option>

              </select>
            </b-input-group>



        </b-col>

        <b-col md="4" cols="6">
          <b-form-group>
            <label for="targetTime">Target Time</label>
            <b-form-input id="targetTime" ref="targetTime" :placeholder="timePlaceholder" v-model="targetTime" :state="validDuration" :formatter="checkTimeInput"/>
            <small v-if="validDuration==false">E.g. '19:27' or 2:09:05'</small>
          </b-form-group>
        </b-col>
      </b-form-row>

        <template v-if="pace">
          <b-form-row>
            <b-col cols="12">
              <div class="result mt-2">Your target pace is: <b>{{ pace }}{{ units == 'miles' ? ' mins/mile' : ' mins/km' }} ({{ speed }}{{ units == 'miles' ? ' mph' : ' kmh' }})</b></div>

            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">

              <div class="mt-2 mb-1" style="font-size:0.8rem">Times for standard distances at this pace:</div>
              <table class="times-table">
                <tr v-for="t in times" :key="t.name">
                  <td>{{ t.name }}</td>
                  <td>{{ t.time }}</td>
                </tr>
              </table>

            </b-col>
          </b-form-row>
        </template>

    </div>

</template>
<script>

export default {

  data: () => ({
    units: 'miles',
    targetTime: '',
    distance: '',
    pace: '',
    speed: '',
    times: []

  }),
  watch: {
    'units': function (newVal,oldVal) {
      if(this.distance){
        if(newVal=="km") {
          this.distance = this.round(this.distance * this.KM_PER_MILE, 3);
        }else if(newVal=="m")  {
          this.distance=this.round(this.distance*this.KM_PER_MILE,3);
        } else {
          this.distance=this.round(this.distance/this.KM_PER_MILE,3);
        }
        this.calc();
      }


    },
    'targetTime': function () {
      this.calc();
    },
    'distance': function () {
      this.calc();
    }
  },

  computed: {
    unitName() {
      if (this.units == "miles") {
        return "mile"
      } else if (this.units == "km") {
        return "km"
      } else {
        return "metre"
      }
    },
    validDistance() {
      if (!this.distance) return null;
      return this.isValidDistance(this.distance);
    },
    validDuration() {
      if (!this.targetTime) return null;
      return this.isValidDuration(this.targetTime);
    },
    displayResult(){
      if(this.pace){
        let text="Your target pace is: <b>"+this.pace+(this.units=='miles'?' mins/mile':' mins/km');
        text+=" ("+this.speed+(this.units=='miles'?' mph':' km/h')+")</b>";
        return text;
      } else {
        return "";
      }
    },

    miles(){
      if(this.distance && this.units) {
        let d = this.distance;
        if (this.units == "km") {
          d = d / 1.60934;
        } else if (this.units == "m") {
          d = d / 1609.34;
        }
        return d;
      } else {
        return 0;
      }
    },
    timePlaceholder(){
      if(this.miles>=40){
        return 'hh:mm:ss'
      } else if(this.miles>=4){
        return 'h:mm:ss'
      } else {
        return 'mm:ss'
      }
    }

  },
  activated() {
    //console.log("PaceCalculator activated");
  },
  deactivated() {

  },
  methods: {
    setDistance(km){
      if(this.units=="miles") {
        this.distance = this.round(km / this.KM_PER_MILE, 3);
      } else if(this.units=="metres"){
          this.distance=this.round(km*1000,3);
      } else {
        this.distance=this.round(km,3);
      }
      this.$nextTick(() => {
        document.getElementById("targetTime").focus();
      });

    },
    setUnits(val){
      this.units=val;
    },

    inputsValid: function () {
      return this.targetTime && this.validDuration && this.distance && this.validDistance
    },

    calc: function () {
      //console.log("Calculate everything");

      if (this.inputsValid()) {
        var distance = this.distance;
        var parts = this.targetTime.split(":");
        var targetHours = 0, targetMins = 0, targetSecs = 0;
        if (parts.length == 1) {
          targetMins = parseInt(parts[0], 10);
        } else if (parts.length == 2) {
          targetMins = parseInt(parts[0], 10);
          targetSecs = parseInt(parts[1], 10);
        } else if (parts.length == 3) {
          targetHours = parseInt(parts[0], 10);
          targetMins = parseInt(parts[1], 10);
          targetSecs = parseInt(parts[2], 10);
        }
        //Need to divide total seconds by total miles to get seconds per mile
        var totalSecs = (3600 * targetHours) + (60 * targetMins) + targetSecs;
        var secsPerDistanceUnit = totalSecs / distance;
        var resultMins = Math.floor(secsPerDistanceUnit / 60);
        var resultSecs = this.round(secsPerDistanceUnit % 60, 1);
        if(resultSecs<10){
          resultSecs='0'+resultSecs;
        }
        this.pace = resultMins + ":" + resultSecs;
        this.speed = this.round(3600 / secsPerDistanceUnit, 2);
        sessionStorage.setItem('pace',this.pace);
        sessionStorage.setItem('units',this.units);

        this.times = [];
        let allDistances=this.trackDistances.concat(this.distances);
        for (var i=0; i < allDistances.length; i++) {
          var d = allDistances[i];
          var dist=d.distance;
          if (this.units == "miles") {
            dist = this.round(dist / this.KM_PER_MILE, 3);
          } else {
            dist = this.round(dist, 3);
          }
          console.log("Pushing "+dist);
          this.times.push({name: d.name, time: this.calcTime(secsPerDistanceUnit, dist,false)});
        }
        console.log(this.times);
      } else {
        this.pace = "";
        this.speed = 0;

      }
    },

    calcTime: function (secsPerDistanceUnit, distance,alwaysShowHours=true) {
      var totalSecs = secsPerDistanceUnit * distance;
      var resultSecs = this.round(totalSecs % 60, 1);
      var totalMins = Math.floor(totalSecs / 60);
      var resultMins = totalMins % 60;
      var totalHours = Math.floor(totalMins / 60);
      var resultHours = totalHours;
      if (resultSecs == 60) {
        resultSecs = 0;
        resultMins++;
      }
      if (resultMins == 60) {
        resultMins = 0;
        resultHours++;
      }
      let secsAsString=resultSecs.toString();
      let parts = secsAsString.split('.');
      if(parts[0].length==1){
        secsAsString='0'+secsAsString;
      }
      let output= resultMins.toString().padStart(2, '0') + ":" + secsAsString;
      if(alwaysShowHours){
        return resultHours + ":" +output;
      } else {
        if(resultHours>0){
          return resultHours + ":" +output;
        } else {
          return output;
        }
      }
    }

  }

}

</script>
<style type="text/scss" scoped>
table.times-table {
  border: 1px dotted black;
  width:50%;

td {
  padding: 3px;
  border: 1px dotted black;
  font-size: 0.8rem;
}
}

</style>