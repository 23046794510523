<template>

  <div>

    <b-form-group>
      <b-form-radio-group v-model="conversion" size="sm"
                          :options="[{ text: 'Mins/mile to mins/km', value: 'miles-to-km' },{ text: 'Mins/km to mins/mile', value: 'km-to-miles' }]" @change="calc">
      </b-form-radio-group>
    </b-form-group>
    <!--      <p class="instruction">Enter distance and target time to get required pace:</p>-->

    <b-form-row>
      <b-col md="4" cols="6">
        <b-form-group>
          <label for="pace">Pace</label>
          <!--            <b-form-input id="pace" placeholder="hh:mm:ss" v-model="pace" class="html-duration-picker"
                                    data-hide-seconds/>-->

          <b-form-input id="pace" placeholder="mm:ss" v-model="pace" :state="validPace" :formatter="checkTimeInput"/>
          <small v-if="validPace==false">Enter pace as e.g. '9:05'</small>

        </b-form-group>
      </b-col>

    </b-form-row>

    <b-form-row>
      <b-col cols="12">
        <div v-if="result" class="result mt-1">{{result}}
        </div>
      </b-col>
    </b-form-row>
  </div>

</template>
<script>

export default {
  data: () => ({
    conversion: 'miles-to-km',
    pace:'',
    result:null

  }),
  watch: {
    'pace': function () {
      this.calc();
    },

  },

  computed: {

    validPace() {
      if (!this.pace) return null;
      return this.isValidPace(this.pace);
    },

  },
  mounted() {
    //HtmlDurationPicker.init();
  },
  methods: {

    reset:function(){
      this.pace='';
    },

    calc: function () {
      //console.log("Calculate everything");

      if (this.validPace) {
        let parts=this.pace.split(":")
        console.log(parts);
        let totalSecs=0;
        let hours=0,mins=0,secs=0;
        if(parts.length==3){
          hours=parseInt(parts[0]);
          mins=parseInt(parts[1]);
          secs=parseFloat(parts[2]);
        } else {
          mins=parseInt(parts[0]);
          secs=parseFloat(parts[1]);
        }

        totalSecs=(3600*hours)+(60*mins)+secs;
        if(this.conversion=='miles-to-km') {
          totalSecs=this.round(totalSecs/this.KM_PER_MILE,1);
        } else {
          totalSecs=this.round(totalSecs*this.KM_PER_MILE,1);
        }
        console.log("totalSecs= "+totalSecs);hours=Math.floor(totalSecs/3600);
        totalSecs=totalSecs-(hours*3600);
        mins=Math.floor(totalSecs/60);
        secs=this.round(totalSecs-(mins*60),1);

        this.result=mins+":"+this.pad(secs);
        if(this.conversion=='miles-to-km') {
           this.result+= " mins/km";
        } else {
          this.result+= " mins/mile";
        }
     /*   if(this.conversion=='miles-to-km') {
          let val=this.round(this.distance*1.60934,3);
          this.result=val+" km";
        } else {
          let val=this.round(this.distance/1.60934,3);
          this.result=val+" miles";
        }*/


      } else {
        this.result="";
      }
    }
  }

}

</script>
<style type="text/scss" scoped>


</style>